import {http, param} from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'
export const API_ROOT = `${conf.API_URL}`
const erResponseService={
    findMeta() {
        return http.get(`${API_ROOT}/erresponse/meta-erresponse`).then(resp => {
            return resp
        })
    },
    fetchRecallSites(){
        return http.get(`${API_ROOT}/erresponse/recall-sites`).then(resp => {
            return resp
        })
    },
    findUserName(orgId) {
        return http.get(`${API_ROOT}/erresponse/meta-erresponse/username/${orgId}`).then(resp => {
            return resp
        })
    },
    findProviderName() {
        return http.get(`${API_ROOT}/erresponse/meta-erresponse/provider`).then(resp => {
            return resp
        })
    },
    getERResponseRole(id) {
        return http.get(`${API_ROOT}/erresponse/role/${id}`).then(resp => {
            return resp
        })
    },
    findErResponse(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            {query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null},
        ]
        return http.get(`${API_ROOT}/erresponse/${param(params)}`).then(resp => {
            return resp
        })
    },
    findNotiErResponse(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim()) : '' },
            { query: 'supplierOrgName', val: payload.supplierOrgName ? encodeURIComponent(payload.supplierOrgName.trim()) : '' },
            { query: 'responseStatus', val: payload.responseStatus },
            { query: 'affectedProduct', val: payload.affectedProduct },
            { query: 'respondent', val: payload.respondent ? encodeURIComponent(payload.respondent.trim()) : '' },
            { query: 'providername', val: payload.providername ? encodeURIComponent(payload.providername) : '' },
            { query: 'organizationId', val: payload.organizationId },
            { query: 'recallsite', val: payload.recallsite },
        ]
        return http.get(`${API_ROOT}/erresponse/notisphere/${param(params)}`).then(resp => {
            return resp
        })
    },
    findErDocuResponse(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'supplierOrgName', val: payload.supplierOrgName },
            { query: 'responseStatus', val: payload.responseStatus },
            { query: 'affectedProduct', val: payload.affectedProduct },
            { query: 'organizationId', val: payload.organizationId },
        ]
        return http.get(`${API_ROOT}/erresponse/document/${param(params)}`).then(resp => {
            return resp
        })
    },
    saveResponderRecall (payload) {
        return http.post(`${API_ROOT}/erresponse/responder`, payload).then(resp => {
            return resp
        })
    },
    sendResponderMail (payload,recallId) {
        return http.post(`${API_ROOT}/erresponse/responderMail/${recallId}`, payload).then(resp => {
            return resp
        })
    },
    updateResponderRecall (payload,recallId,actionMode) {
        return http.post(`${API_ROOT}/erresponse/update/${recallId}/mode/${actionMode}`, payload).then(resp => {
            return resp
        })
    },
    deleteResponderRecall (recallId) {
        return http.put(`${API_ROOT}/erresponse/delete/${recallId}`).then(resp => {
            return resp
        })
    },
    downloadCSVResponderRecall () {
        return http.get(`${API_ROOT}/erresponse/downloadcsv`,{ responseType: 'blob' }).then(resp => {
            var date = new Date()
            var DateTime = (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))  + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +'-'+ date.getFullYear())
            fileDownload(resp.data, 'eR Responses Report ' + DateTime +'.csv','text/csv')
        })
    },
    downloadXLSXResponderRecall () {
        return http.get(`${API_ROOT}/erresponse/downloadxlsx`,{ responseType: 'blob' }).then(resp => {
            var date = new Date()
            var DateTime = (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))  + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +'-'+ date.getFullYear())
            fileDownload(resp.data, 'eR Responses Report ' + DateTime +'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },

    getSites () {
        return http.get(`${API_ROOT}/erresponse/sites`).then(resp => {
            return resp
        })
    },
    Download (url,name) {
        return http.get(url, { responseType: 'blob' }).then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]))
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            fileLink.click()
        })
    },
    GetSupplierNameList () {
        return http.get(`${API_ROOT}/erresponse/supplierNameList`).then(resp => {
            return resp
        })
    },
    findSupplierContacts(orgId) {
        return http.get(`${API_ROOT}/erresponse/suppliercontact/${orgId}`).then(resp => {
            return resp
        })
    },
    getAutoNotifySetting() {
        return http.get(`${API_ROOT}/erresponse/AutoNotify`).then(resp => {
            return resp
        })
    },
}
export default erResponseService